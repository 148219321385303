// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #b19cd9; /* Dark purple */
  color: #ffffff; /* White text for contrast */
}

.centerText {
  text-align: center;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.6);
}

.card-image {
  width: 100%;
  height: 150px;
  object-fit: contain; /* Ensures image fills the container while maintaining aspect ratio */
}

.container {
  background-color: #570987;
  width: 100%;
}

.card {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.card-header {
  background-color: #51265d; /* A slightly darker purple for the header */
  color: #fff;
  font-weight: bold;
}

.card-body {
  background-color: #dcd6f7; /* Light lavender for contrast */
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/Home.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,gBAAgB;EAC3C,cAAc,EAAE,4BAA4B;AAC9C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,0CAA0C;AAC5C;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB,EAAE,qEAAqE;AAC5F;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB,EAAE,4CAA4C;EACvE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,yBAAyB,EAAE,gCAAgC;EAC3D,WAAW;AACb","sourcesContent":["body {\n  background-color: #b19cd9; /* Dark purple */\n  color: #ffffff; /* White text for contrast */\n}\n\n.centerText {\n  text-align: center;\n}\n\n.card:hover {\n  transform: translateY(-10px);\n  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.6);\n}\n\n.card-image {\n  width: 100%;\n  height: 150px;\n  object-fit: contain; /* Ensures image fills the container while maintaining aspect ratio */\n}\n\n.container {\n  background-color: #570987;\n  width: 100%;\n}\n\n.card {\n  border: 1px solid #ddd;\n  border-radius: 10px;\n  overflow: hidden;\n}\n\n.card-header {\n  background-color: #51265d; /* A slightly darker purple for the header */\n  color: #fff;\n  font-weight: bold;\n}\n\n.card-body {\n  background-color: #dcd6f7; /* Light lavender for contrast */\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
